import { useEffect, useRef } from 'react'

export const useAbortController = () => {
  const abortControllerRef = useRef<AbortController>(new AbortController())

  useEffect(() => {
    return () => {
      abortControllerRef.current.abort()
    }
  }, [])

  const resetAbortController = () => {
    abortControllerRef.current = new AbortController()
  }

  const abort = () => {
    abortControllerRef.current.abort()
  }

  return {
    abortControllerRef,
    abort,
    resetAbortController,
  }
}
