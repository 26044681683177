import { isNil } from 'lodash'

export function asyncTimeout<T>(t: number, v: T): Promise<T> {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null, v), t)
  })
}

export async function asyncForEach<T>(
  array: T[],
  callback: (value: T, index: number, array: T[]) => Promise<void> | void,
  cancelIndicator = false,
): Promise<void> {
  for (let index = 0; index < array.length; index++) {
    if (cancelIndicator) break
    await callback(array[index], index, array)
  }
}

export const asyncFilter = async <T>(
  arr: T[],
  predicate: (value: T, index: number, array: T[]) => Promise<boolean>,
): Promise<T[]> => {
  const results = await Promise.all(arr.map(predicate))
  return arr.filter((_v, index) => results[index])
}

export const snakeToCamel = (str: string): string =>
  str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''))

export const camelToSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const camelToSnake = (key: string): string => key.replace(/([A-Z])/g, '_$1').toLowerCase()

export function objectRemoveEmptyKeys<T extends Record<string, any>>(obj: T): Partial<T> {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !isNil(v))) as Partial<T>
}

export function isBEError(error: any): error is any[] {
  return Array.isArray(error)
}
