/**
 * @description Default storage with any hook or states.
 *  web -> local storage only
 *  app -> mmkv only
 **/
export * from './default'
/**
 * @description Storage with state management(jotai atom).
 *  web -> atom + local storage
 *  app -> atom + mmkv
 **/
export * from './atom'

export type * from './types'
