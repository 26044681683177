import type { StorageType } from './types'

export const storage: Omit<StorageType, 'setString' | 'setNumber' | 'setBoolean' | 'setJSON'> = {
  setItem: (key: string, value: string) => {
    localStorage.setItem(key, value)
  },
  getItem: (key: string) => {
    return localStorage.getItem(key) || undefined
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key)
  },
  getString: (key: string) => {
    return localStorage.getItem(key) ?? undefined
  },
  getNumber: (key: string) => {
    const value = localStorage.getItem(key)
    return value ? Number(value) : undefined
  },
  getBoolean: (key: string) => {
    const value = localStorage.getItem(key)
    return value ? value.toLowerCase() === 'true' : undefined
  },
  getJSON<T>(key: string): T | undefined {
    const json = localStorage.getItem(key)
    if (json) {
      try {
        return JSON.parse(json) as T
      } catch (e) {
        console.error('Error parsing JSON:', e)
      }
    }
    return undefined
  },
  clearAll: (): void => {
    localStorage.clear()
  },
  getAllKeys: (): string[] => {
    return Object.keys(localStorage)
  },
  contains: (key: string): boolean => {
    return localStorage.getItem(key) !== null
  },
}
