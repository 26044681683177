import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'

import { meAtom } from '@/store/me'
import { useAtomValue } from 'jotai'
import { getIntercomUserId } from '@wordup/utils'

const Intercom = ({ openIntercom }: { openIntercom: boolean }) => {
  const { shutdown: shutdownIntercom, boot: bootIntercom } = useIntercom()
  const user = useAtomValue(meAtom)
  const { id: userId, name, email } = user?.data.attributes ?? {}

  useEffect(() => {
    if (!bootIntercom || !openIntercom || !userId) return

    bootIntercom({
      userId: getIntercomUserId(String(userId), process.env.NEXT_PUBLIC_STAGE),
      name,
      email,
    })
  }, [bootIntercom, userId, user, openIntercom])

  useEffect(() => () => shutdownIntercom?.(), [shutdownIntercom])

  return null
}

export default Intercom
