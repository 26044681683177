import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import { storage } from './default.web'

function getItem(key: string): string | null {
  const value = storage.getString(key)
  return value ? value : null
}

function setItem(key: string, value: boolean | string | number | Uint8Array): void {
  storage.setItem(key, value)
}

function removeItem(key: string): void {
  storage.removeItem(key)
}

function clearAll(): void {
  storage.clearAll()
}

export const atomWithLocalStorage = <T>(key: string, initialValue: T) =>
  atomWithStorage<T>(
    key,
    initialValue,
    createJSONStorage<T>(() => ({
      getItem,
      setItem,
      removeItem,
      clearAll,
    })),
  )
