import { useEffect } from 'react'
import { IntercomProps, useIntercom } from 'react-use-intercom'

import useAuth from '@/hooks/use-auth'
import * as utilsFns from './utils'
import { useAtom } from 'jotai'

export const utils = utilsFns

interface Props {
  allowGuest?: boolean
}

const useWuIntercom = () => {
  const { show, shutdown, hide, boot, ...restIntercomObj } = useIntercom()

  const rebootIntercom = (props?: IntercomProps) => {
    shutdown()
    boot(props)
  }

  return {
    ...restIntercomObj,
    rebootIntercom,
    closeIntercomMessenger: hide,
    openIntercomMessenger: show,
    shutdownIntercom: shutdown,
  }
}
export default useWuIntercom
