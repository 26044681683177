import { atom } from 'jotai'

import { GetMeResponse } from '@/typings/api'
import cookieStorage from '@/utils/cookie-storage'
import { COOKIE_KEYS } from '@libs-components/utils/cookie-storage'

export const meAtom = atom<GetMeResponse | undefined>(undefined)

export const setMeAtom = atom(null, (_get, set, info: GetMeResponse) => set(meAtom, info))

export const clearMeAtom = atom(null, (_get, set) => set(meAtom, undefined))

export const isSchoolAdminAtom = atom(false)

export const isWuAdminAtom = atom(false)

export const orgAtom = atom<OrgAtomTypes>({
  orgId: '',
  orgName: '',
  occupiedSeatCount: 0,
  purchasedSeatsCount: 0,
})

type OrgAtomTypes = {
  orgId: string
  orgName: string
  occupiedSeatCount: number
  purchasedSeatsCount: number
}

export const saveOrgIdInCookie = (orgId: string) => {
  cookieStorage.set({
    key: COOKIE_KEYS.LAST_SELECTED_ORG_ID,
    value: JSON.stringify(orgId),
    domain: process.env.REACT_APP_ROOT_DOMAIN as string,
  })
}

export const orgIdFromCookie = cookieStorage.get(COOKIE_KEYS.LAST_SELECTED_ORG_ID, true) as string
