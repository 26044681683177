import { differenceInDays, fromUnixTime, addDays, startOfWeek, format, parseISO } from 'date-fns'
import isNil from 'lodash/isNil'

export * from 'date-fns'

export const formatDateTime = (datetime: Date, formatStr: string) => {
  if (!datetime) return ''
  return format(datetime, formatStr)
}

export const toDateStr = (datetime?: Date) => {
  if (!datetime) return ''
  return formatDateTime(datetime, 'yyyy-MM-dd')
}

export const msToHumanFormat = (givenMs?: number | null) => {
  if (isNil(givenMs)) return ''

  function pad(n: number, z?: number) {
    z = z || 2
    return ('00' + n).slice(-z)
  }

  const ms = givenMs % 1000
  givenMs = (givenMs - ms) / 1000
  const secs = givenMs % 60
  givenMs = (givenMs - secs) / 60
  const mins = givenMs % 60
  const hrs = (givenMs - mins) / 60

  if (hrs) {
    return pad(hrs) + ':' + pad(mins) + ':' + pad(secs)
  } else {
    return pad(mins) + ':' + pad(secs)
  }
}

const wDayMapping = {
  mon: 0,
  tue: 1,
  wed: 2,
  thu: 3,
  fri: 4,
  sat: 5,
  sun: 6,
}

export const wdayToDate = (basedDate: Date, wuWdayStr: keyof typeof wDayMapping) => {
  const startDateOfWeek = startOfWeek(basedDate, { weekStartsOn: 1 })

  const wDayIdx = wDayMapping[wuWdayStr]
  return addDays(startDateOfWeek, wDayIdx)
}

export const dateStrToWday = (dateStr: string) => {
  const date = parseISO(dateStr)
  return format(date, 'EEE')
}

export const isLargerThanTime = (timestamp: string, thresholdInDay: number) => {
  const diffDay = differenceInDays(new Date(), fromUnixTime(parseInt(timestamp, 10)))
  return diffDay > thresholdInDay
}

export const parseTimeStringToMSeconds = (timeString?: string) => {
  if (!timeString) return 0

  try {
    const [hrMinSecon, mSeconds] = timeString.split('.')
    const [hr, min, second] = hrMinSecon.split(':')
    const timeInMiliSecond =
      3600000 * parseInt(hr, 10) +
      60000 * parseInt(min, 10) +
      1000 * parseInt(second, 10) +
      parseInt(mSeconds, 10)

    return timeInMiliSecond
  } catch (err) {
    return 0
  }
}

export const parseTimeToMilliseconds = (timeString?: string) => {
  if (!timeString) return 0

  const timeParts = timeString.split(':')
  const secondsWithMilliseconds = timeParts.pop() || '0'
  const secondsWithMillisecondsParts = secondsWithMilliseconds.split('.')
  const seconds = secondsWithMillisecondsParts[0] || '0'
  const milliseconds =
    secondsWithMillisecondsParts.length > 1 ? secondsWithMillisecondsParts[1] : '0'
  const minutes = timeParts.length > 0 ? timeParts.pop() || '0' : '0'
  const hours = timeParts.length > 0 ? timeParts.pop() || '0' : '0'

  return (
    (parseInt(hours, 10) * 60 * 60 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10)) * 1000 +
    parseInt(milliseconds, 10)
  )
}
