/*
 * MIGRATION_TRANSFORMED_MARKER
 *
 * Please do not delete this marker;
 * We will remove it after the migration is complete.
 **/

import Swal from '@/utils/sweetalert'
import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { useTranslation } from 'next-i18next'

import useSWR from 'swr'

import { COURSES_KEY, MATERIALS_KEY } from '@/apis/constants'
import useNsSWR from '@/hooks/use-swr'
import { toDashboardMaterial } from '@/routes/helper'
import ErrorNotifier, { isNotifiableError } from '@/utils/error-notifier'
import MaterialService from '.'

import toast from '@/components/toast'
import { useRouter } from 'next/router'
import { GetPracticeCategoriesResponseTypes } from '../student/material/types'
import { GetMaterialEssentialResponseDataType, GetMaterialPassesResponseDataType } from './type'

interface UseMaterialProps {
  id: string
  shouldRevalidate: boolean
}

export const useMaterials = ({ shouldRevalidate }: { shouldRevalidate: boolean }) => {
  return useSWR(
    [MATERIALS_KEY],
    async () => {
      const res = await MaterialService.getAll()
      return camelcaseKeys(res.data, { deep: true })
    },
    {
      revalidateIfStale: shouldRevalidate,
      revalidateOnFocus: shouldRevalidate,
      revalidateOnReconnect: shouldRevalidate,
    },
  )
}

export const useMaterial = ({ id, shouldRevalidate }: UseMaterialProps) => {
  const router = useRouter()
  const { t } = useTranslation()

  const { data, isValidating } = useSWR(
    [MATERIALS_KEY, id],
    async () => {
      try {
        const res = await MaterialService.get(id)
        if (res.status === 200) {
          return camelcaseKeys(res.data, { deep: true })
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          Swal.fire({
            title: t('empty.no_teacher_access.title'),
            text: t('empty.no_teacher_access.popup_desc'),
            icon: 'warning',
          })
          router.push({ pathname: toDashboardMaterial() })
        }
        if (isNotifiableError(err)) {
          ErrorNotifier.notify({
            err,
            context: {
              key: 'get material error',
              materialId: id,
            },
          })
        }
      }
    },
    {
      revalidateIfStale: shouldRevalidate,
      revalidateOnFocus: shouldRevalidate,
      revalidateOnReconnect: shouldRevalidate,
    },
  )
  return {
    material: data?.data,
    materialMeta: data?.meta,
    isLoading: !data && isValidating,
  }
}

export const useMaterialCourse = ({ id, shouldRevalidate }: UseMaterialProps) => {
  const { t } = useTranslation()
  const { data, ...restProps } = useSWR(
    id ? [COURSES_KEY, id] : null,
    async () => {
      try {
        const res = await MaterialService.getCourse(id)
        if (res.status === 200) {
          return camelcaseKeys(res.data, { deep: true })
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          toast.error({ message: err.response?.data?.errors?.[0]?.detail ?? t('err.happens') })
        }
      }
    },
    {
      revalidateIfStale: shouldRevalidate,
      revalidateOnFocus: shouldRevalidate,
      revalidateOnReconnect: shouldRevalidate,
    },
  )
  return {
    ...restProps,
    data: data?.data,
    languageOptions: data?.languageOptions,
  }
}

export const useMaterialEssential = ({ materialId }: { materialId: string }) => {
  const { data, mutate, isValidating } = useNsSWR<{
    data: GetMaterialEssentialResponseDataType
  }>({
    apiConfig: MaterialService.getMaterialsEssential(materialId),
    swrConfig: {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  })
  const isLoading = !data?.data && isValidating
  return { materialEssential: data?.data, mutate, isLoading }
}

export const useMaterialPasses = ({ materialId }: { materialId?: string }) => {
  const { data, mutate, isValidating } = useNsSWR<GetMaterialPassesResponseDataType>({
    apiConfig: materialId ? MaterialService.getMaterialsPasses(materialId) : null,
    swrConfig: {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  })
  const isLoading = !data?.meta && isValidating
  return { materialPasses: data?.meta.availableCount, mutate, isLoading }
}

export const useGetMaterialsAchievements = ({
  materialId,
  levelId,
  unreadOnly,
}: {
  materialId: string
  levelId?: string
  unreadOnly?: boolean
}) => {
  const { data, mutate, isValidating } = useNsSWR<GetPracticeCategoriesResponseTypes>({
    apiConfig: levelId
      ? MaterialService.getMaterialsAchievements({ materialId, levelId, unreadOnly })
      : null,
    swrConfig: {
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateIfStale: false,
    },
  })
  const isLoading = !data?.data && isValidating
  return { materialAchievements: data?.data, mutate, isLoading }
}
